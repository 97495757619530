
import { Options, Vue } from 'vue-class-component';
import HelloWorld from '@/components/HelloWorld.vue'; // @ is an alias to /src
import CountDownTimer from '@/components/CountDownTimer.vue';
import LodgingSection from '@/components/LodgingSection.vue';
import RegistrySection from '@/components/RegistrySection.vue';
import WeddingVenue from '@/components/WeddingVenue.vue';
import RsvpLink from '@/components/RsvpLink.vue';
import PhotoViewer from '@/components/PhotoViewer.vue';
import YourPhotos from '@/components/YourPhotos.vue';
import OurPhotos from '@/components/OurPhotos.vue';
import WeddingParty from '@/components/WeddingParty.vue';
import Map from '@/components/Map.vue';
import ThankYou from '@/components/ThankYou.vue';
import WeddingPhotos from '@/components/WeddingPhotos.vue';
import WeddingVideo from '@/components/WeddingVideo.vue';
import EngagementVideo from '@/components/EngagementVideo.vue';

@Options({
  components: {
    HelloWorld,
    CountDownTimer,
    LodgingSection,
    OurPhotos,
    PhotoViewer,
    RegistrySection,
    RsvpLink,
    WeddingVenue,
    YourPhotos,
    WeddingParty,
    Map,
    ThankYou,
    WeddingPhotos,
    WeddingVideo,
    EngagementVideo,
  },
})
export default class HomeView extends Vue {

}
