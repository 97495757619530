
import { Vue, Options } from 'vue-class-component';
import { weddingPhotoImageLinks } from '@/assets/image-links/WeddingPhotoImageLinks';
import PhotoViewer from '@/components/PhotoViewer.vue';
import { IPhoto } from '@/models/IPhoto';

@Options({
  components: {
    PhotoViewer,
  },
})
export default class YourPhotos extends Vue {

  weddingPhotos: IPhoto[] = weddingPhotoImageLinks;

}
