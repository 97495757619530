import IPlusOne from "./IPlusOne";

export default interface IRsvpResponse {

  /** The date this RSVP request was created and submitted, in MS since epoch */
  dateCreated: number;

  /** The guest who created this RSVP response. Can be different from the guest
   * id if their party has more than one person. */
  responderId: string;

  /** The id of the guest party that this RSVP response is from */
  partyId: string;

  /** A list of all guests in this party who have said 'yes' they will be coming
   * to our wedding */
  guestsWhoSaidYesIds: string[];

  /** A list of all guests in this party who have said 'no' they will NOT coming
   * to our wedding */
  guestsWhoSaidNoIds: string[];

  /** The plus one that this guest is bringing, if they decided to bring on. If
   * the decided not to bring one, this will be null. */
  plusOne: IPlusOne | null;

  /** Any advice or jokes this guest wants to leave for us */
  adviceOrJokes?: string;

  /** Any song recommendations this guests has for us */
  songRecommendations?: string;

  /** The reason that this guest cannot attent the wedding.
   * @note This property should only be populated if no guests from this party
   * can attend the wedding. Even then, it is still an optional field, and may
   * be an empty string. */
  reasonForDecline?: string;

  /** Only true if this object was created in a non-production environment, for
   * filtering from the data base later */
  devMode?: boolean;

}

/** Creates an RSVP response for a guest party that canno attend our wedding */
export function createPartyNoRsvp(
  responderId: string,
  partyId: string,
  guestsWhoSaidNoIds: string[],
  reasonForDecline: string | null
): IRsvpResponse {
  const rsvpResponse = createRsvpResponse(
    responderId,
    partyId,
    [],
    guestsWhoSaidNoIds,
    null,
    null,
    null,
    reasonForDecline
  );
  return rsvpResponse;
}

/** Creates an RSVP response for a guest party that can attend our wedding */
export function createPartyYesRsvp(
  responderId: string,
  partyId: string,
  guestsWhoSaidYesIds: string[],
  guestsWhoSaidNoIds: string[],
  plusOne: IPlusOne | null,
  adviceOrJokes: string | null,
  songRecommendations: string | null
): IRsvpResponse {
  const rsvpResponse = createRsvpResponse(
    responderId,
    partyId,
    guestsWhoSaidYesIds,
    guestsWhoSaidNoIds,
    plusOne,
    adviceOrJokes,
    songRecommendations,
    null
  );
  return rsvpResponse;
}

/**
 * Creates an RSVP response fromt he given data. 
 * @Note instead of making IRsvpResponse objects manually, this method provides
 * a way to saftley construct the object and insure all fields (and empty
 * fields) are populated correcty, especially with regards to how data is sent
 * to and retrieved fromt he Firestore database.
 */
export function createRsvpResponse(
  responderId: string,
  partyId: string,
  guestsWhoSaidYesIds: string[],
  guestsWhoSaidNoIds: string[],
  plusOne: IPlusOne | null,
  adviceOrJokes: string | null,
  songRecommendations: string | null,
  reasonForDecline: string | null,
  dateCreated: number | null = null,
): IRsvpResponse {
  const rsvpResponse: IRsvpResponse = {
    dateCreated: dateCreated ? dateCreated : Date.now(),
    responderId: responderId,
    partyId: partyId,
    guestsWhoSaidYesIds: guestsWhoSaidYesIds,
    guestsWhoSaidNoIds: guestsWhoSaidNoIds,
    plusOne: plusOne ? plusOne : null,
    adviceOrJokes: adviceOrJokes ? adviceOrJokes : '',
    songRecommendations: songRecommendations ? songRecommendations : '',
    reasonForDecline: reasonForDecline ? reasonForDecline : '',
    devMode: process.env.NODE_ENV != 'production',
  };
  return rsvpResponse;
}


