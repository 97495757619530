/**
 * Data related to a single guest who is invited to the wedding. May be part of
 * a wedding party.
 */
export default interface IGuest {

  /** Unique GUID for this guests */
  id: string;

  /** The first name of this guest */
  firstName: string;

  /** The last name of this guest */
  lastName: string;

  /** The email of this guest */
  email: string;

  /** True onyl if this guest is allowed to bring a plus one */
  hasPlusOne: boolean;

  /** Used as an ID to group this guest into the guest party to which it
   * belongs. Ie: Multiple guests in the same household will only recieve one
   * invitation, etc.*/
  invitationNumber: string;

}

/**
 * Creates a list of IGuest objects from the given CSV string. Also issues
 * console warnings for guests with incomplete information.
 * @param guestListCsv a CSV, as a string (with commans and line breaks) that
 * represents the latest guest list from the Google Drive wedding planning
 * spreadsheet
 * @returns A list of IGuests from that CSV, or an empty list if the parsing
 * failed.
 */
export function fromCsvGuestList(guestListCsv: string): IGuest[] {
  try {

    // break into rows
    const rows: string[] = guestListCsv.split('\n');

    // create headders from first row
    const headers: string[] = rows[0].split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/); // csv parser, seperates by commas that are not inside of quotations.

    // get index of header columns we need
    const idIndex = headers.indexOf('Id');
    const firstNameIndex = headers.indexOf('First name');
    const lastNameIndex = headers.indexOf('Last name');
    const emailIndex = headers.indexOf('E-mail Address');
    const isPlusOneIndex = headers.indexOf('Is Plus One');
    const hasPlusOneIndex = headers.indexOf('Has Plus One');
    const invitationNumberIndex = headers.indexOf('Invitation Number');

    // issue warning if a requried headder was not found
    if (
      idIndex === -1 ||
      firstNameIndex === -1 ||
      lastNameIndex === -1 ||
      emailIndex === -1 ||
      isPlusOneIndex === -1 ||
      hasPlusOneIndex === -1 ||
      invitationNumberIndex === -1
      ) {
      console.error("Gust List CSV Parsing Error: One or more of the required headder names did not match the values in the prvided Guest List CSV");
    }

    // create updated guest list from CSV
    const guests: IGuest[] = [];
    for (let i = 1; i < rows.length; i++) {

      // parase each guest row into columns
      const row = rows[i].split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/); // csv parser, seperates by commas that are not inside of quotations.

      // if row has no ID, we assume it's in invalid guest row and we skip it!
      // we also skip rows that are for guests who are potential plus ones, as that data will be added in the RSVPs
      const id: string = row[idIndex];
      const isPlusOne: boolean = row[isPlusOneIndex].toLowerCase() == 'x' ? true : false;
      if (id == '' || isPlusOne) {
        continue;
      }

      // get frist name, last name, email for this guest from CSV
      const firstName: string = row[firstNameIndex].toLowerCase();
      const lastName: string = row[lastNameIndex].toLowerCase();
      const email: string = row[emailIndex].toLowerCase();
      const invitationNumber: string = row[invitationNumberIndex];

      // issues warnings if first name, last name, or email were not in chart
      if (firstName == '') console.warn(`Guest "${id}, ${firstName}, ${lastName}, ${email}, row ${i + 1}"  missing first name`);
      if (lastName == '') console.warn(`Guest "${id}, ${firstName}, ${lastName}, ${email}, row ${i + 1}"  missing last name`);
      if (email == '') console.warn(`Guest "${id}, ${firstName}, ${lastName}, ${email}, row ${i + 1}"  missing email`);

      // create guest object, add to list
      const guest: IGuest = {
        id: row[idIndex],
        firstName: row[firstNameIndex].toLowerCase().trim(),
        lastName: row[lastNameIndex].toLowerCase().trim(),
        email: row[emailIndex].toLowerCase().trim(),
        hasPlusOne: row[hasPlusOneIndex].toLowerCase().trim() == 'x' ? true : false,
        invitationNumber: invitationNumber.toLowerCase().trim(),
      };
      guests.push(guest);
    }

    return guests;

  } catch (error) {
    console.error('Failed to parse Guest List CSV: ' + error);
    return [];
  }

}
