
import { Vue } from 'vue-class-component';

export default class HomeView extends Vue {

  /** True if the (mobile) nav menu should currently be shown. False if only the
   * menu icon should be shown */
  showMenu: boolean = false;

  /** Returns true if this device's width is smaller than 600px */
  get isMobile(): boolean {
    return window.innerWidth < 600;
  }

  /** Toggles the visibility of the mobile nav manu */
  toggleMenu(): void {
    this.showMenu = !this.showMenu;
  }

}

