
import { Vue, Options } from 'vue-class-component';
import RegistryLink from '@/components/RegistryLink.vue';

@Options({
  components: {
    RegistryLink,
  },
})
export default class HomeView extends Vue {
  public get screenWidth(): number {
    return window.innerHeight;
  }
  mounted(): void {
    // let foo = document.createElement('script');    
    // foo.innerHTML='!function(e,t,n){var s,a=e.getElementsByTagName(t)[0];e.getElementById(n)||(s=e.createElement(t),s.id=n,s.async=!0,s.src="https://widget.zola.com/js/widget.js",a.parentNode.insertBefore(s,a))}(document,"script","zola-wjs");';
    // (this as any).$refs.zolereg.appendChild(foo);
  }
}
