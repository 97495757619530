
import { IHotel } from '@/models/IHotel';
import { PropType, defineComponent } from 'vue';

export default defineComponent({
  props: {
    /** A URL link to the hotel, preferable a link from which you can book a
     * room */
    bookingUrl: {
      required: true,
      type: String,
    },
    /** A URL link to the google maps page on this hotel */
    googleMapsUrl: {
      required: true,
      type: String,
    },
    imageName: {
      required: true,
      type: String,
    },
    imageDescription: {
      required: false,
      type: String,
      default: "Picture of hotel"
    },
    /** Data about this hotel */
    hotel: {
      required: true,
      type: Object as PropType<IHotel>,
    },
  },
  data() {
    let showExpanderContent: boolean = false;
    return {
      /** When true, the content of the expander is visible. When false, it is
       * hidden */
      showExpanderContent,
    }
  },
  computed: {
    /** The text shown inside the header of the expander */
    expanderHeaderText: function (): string {
      return this.showExpanderContent ? 'See less...' : 'See more...';
    },
  },
  methods: {
    /** Toggles the visible of the expander conent inside the expander */
    toggleExpander: function (): void {
      this.showExpanderContent = !this.showExpanderContent;
    }
  },
})
