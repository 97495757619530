
import IGuest, { fromCsvGuestList } from '@/models/IGuest';
import { FirebaseApp } from 'firebase/app';
import { Options, Vue } from 'vue-class-component';
import { Firestore, getDocs, collection, QuerySnapshot, DocumentData, updateDoc, deleteDoc, setDoc, doc, DocumentReference, QueryDocumentSnapshot, CollectionReference, writeBatch, addDoc, WriteBatch } from "firebase/firestore";
import { initializeWeddingRsvpFirebaseApp, getFirestoreDatabase, guestCollectionName, partiesCollectionName, getAllParties } from '@/utilities/FirebaseUtils';
import IGuestParty, { fromGuests } from '@/models/IGuestParty';
import { Auth, GoogleAuthProvider, getAuth, signInWithPopup } from 'firebase/auth';
import IRsvpResponse from '@/models/IRsvpResponse';
import { getLatestRsvp, getGuests } from '@/utilities/FirebaseUtils';
import { IRsvpView } from '@/models/IRsvpView';



@Options({
  components: {
  },
})
export default class HomeView extends Vue {

  //#region DATA

  auth: Auth | null = null;
  database: Firestore | null = null;
  firebaseApp: FirebaseApp | null = null;

  latestRsvps: IRsvpView[] = [];

  //#endregion DATA

  //#region COMPUTED

  get isAdminLoggedIn() {
    return this.auth != null && 
      (this.auth.currentUser?.email == "daniel.n.kaye@gmail.com" || this.auth.currentUser?.email == "tayag.regina@gmail.com");
  }

  //#endregion COMPUTED

  //#region METHODS

  async signInWithGoogle() {
    const provider: GoogleAuthProvider = new GoogleAuthProvider();
    await signInWithPopup(this.auth!, provider);
  }

  async submitted(e: Event): Promise<void> {

    // get guests from uploaded csv
    const inputElement: HTMLInputElement = this.$refs.theFile as HTMLInputElement;
    const csvGustList: string = await inputElement.files![0].text();
    const updatedGuests: IGuest[] = fromCsvGuestList(csvGustList);
    if (updatedGuests.length === 0) {
      return;
    }

    // get parties from updated guest list
    const updatedParties: IGuestParty[] = fromGuests(updatedGuests);

    // get wedding-rsvp firestore database
    this.database = getFirestoreDatabase();
    if (this.database == null) {
      return;
    }

    // get current parties DB from Firestore
    let partiesDb: QuerySnapshot<DocumentData>;
    try {
      console.log('Getting parties database...')
      partiesDb = await getDocs(collection(this.database!, partiesCollectionName));
      console.log('Got parties database!')
    } catch (error) {
      console.log('Failed to fetch parties database: ' + error);
      return;
    }

    // delete all existing docs from 'parties' database
    // note: if there are more thn 500 (Firestore batch limit) this wont work, and will need to be split into multiple batches
    try {
      console.log('Deleting all previous parties...');
      const batch = writeBatch(this.database);
      for (let doc of partiesDb.docs) {
        batch.delete(doc.ref)
      }
      await batch.commit();
      console.log('All previous parties deleted!');
    } catch (error) {
      console.warn(error);
      console.log('Failed to delete all previous parties');
    }

    // add all updated parties
    try {

      console.log('Adding updated parties...');

      const batchPartyUpdate: WriteBatch = writeBatch(this.database);
      for (let updatedParty of updatedParties) {
        const newDocId: string = updatedParty.id;
        const docRef: DocumentReference<DocumentData> = doc(this.database, partiesCollectionName, newDocId);
        batchPartyUpdate.set(docRef, updatedParty);
      }
      // try to post updated party documents to database
      await batchPartyUpdate.commit();
      console.log('Added updated parties!');
    } catch (error) {
      console.error(error);
      console.log('Failed to update parties.');
    }

    // get current guests DB from Firestore
    let guestsDb: QuerySnapshot<DocumentData>;
    try {
      console.log('Getting guests database...')
      guestsDb = await getDocs(collection(this.database!, guestCollectionName));
      console.log('Got guests database!')
    } catch (error) {
      console.log('Failed to fetch guests database: ' + error);
      return;
    }

    // delete all existing docs from 'guests' database
    // note: if there are more thn 500 (Firestore batch limit) this wont work, and will need to be split into multiple batches
    try {
      console.log('Deleting all previous guests...');
      const batch = writeBatch(this.database);
      for (let doc of guestsDb.docs) {
        batch.delete(doc.ref)
      }
      await batch.commit();
      console.log('All previous guests deleted!');
    } catch (error) {
      console.warn(error);
      console.log('Failed to delete all previous guests');
    }

    // add all updated guests
    try {

      console.log('Adding updated guests...');

      const batchGuestUpdate: WriteBatch = writeBatch(this.database);
      for (let updatedGuest of updatedGuests) {
        const newDocId: string = updatedGuest.id;
        const docRef: DocumentReference<DocumentData> = doc(this.database, guestCollectionName, newDocId);
        batchGuestUpdate.set(docRef, updatedGuest);
      }
      // try to post updated party documents to database
      await batchGuestUpdate.commit();
      console.log('Added updated guests!');
    } catch (error) {
      console.error(error);
      console.log('Failed to update guests.');
    }

  }

  // comment
  async downloadLatestRevps() {

    try {

      console.log('trying to get guest RSVPs...');

      this.database = getFirestoreDatabase();

      //! get each guests party
      const allParties: QuerySnapshot<DocumentData> | null = await getAllParties(this.database!);
      if (allParties == null) {
        return;
      }

      //! for each party, find latest rsvp
      const latestRsvps: IRsvpView[] = [];
      for (let party of allParties.docs) {
        const rsvp: IRsvpResponse | null = await getLatestRsvp(this.database!, party.id);
        if (rsvp != null) {
          // add guests in party to NO RSVP list
          for (let guestId of rsvp.guestsWhoSaidYesIds) {
            const guestName: string = await this.getGuestNameFromId(guestId);
            const rsvpView: IRsvpView = {
              date: rsvp.dateCreated,
              partyId: party.id,
              name: guestName,
              attending: true,
              adviceOrJoke: rsvp.adviceOrJokes ? rsvp.adviceOrJokes : '',
              songRecommendation: rsvp.songRecommendations ? rsvp.songRecommendations : '',
            }
            latestRsvps.push(rsvpView);
            if (rsvp.plusOne != null) {
              const plusOneRsvpView: IRsvpView = {
                date: rsvp.dateCreated,
                partyId: party.id,
                name: rsvp.plusOne.firstName + ' ' + rsvp.plusOne.lastName,
                attending: true,
              }
              latestRsvps.push(plusOneRsvpView);
            }
          }
          for (let guestId of rsvp.guestsWhoSaidNoIds) {
            const guestName: string = await this.getGuestNameFromId(guestId);
            const rsvpView: IRsvpView = {
              date: rsvp.dateCreated,
              partyId: party.id,
              name: guestName,
              attending: false,
              reasonForDeclining: rsvp.reasonForDecline ? rsvp.reasonForDecline : '',
            }
            latestRsvps.push(rsvpView);
          }
        }
      }

      this.latestRsvps = latestRsvps.sort(this.compareDates);
      console.log(this.latestRsvps);
      console.log('Got all guest RSVPs!');
    } catch (error) {
      console.warn(error);
      console.log('failed to get guest RSVPs')
    }

  }

  compareDates(a: IRsvpView, b: IRsvpView): number {
    if (a.date < b.date) {
      return 1;
    }
    if (a.date > b.date) {
      return -1;
    }
    // a must be equal to b
    return 0;
  }

  // comment
  async getGuestNameFromId(guestId: string): Promise<string> {
    const guests: IGuest[] = await getGuests(this.database!, [guestId]);
    return guests[0].firstName + ' ' + guests[0].lastName;
  }

  dateColor(date: number): string {
    const daysSinceRsvpSent: number = Math.round((Date.now() - date) / 86400000); // ms per day
    if(daysSinceRsvpSent < 1){
      return "red";
    } else if(daysSinceRsvpSent < 7){
      return "orange";
    } else {
      return "lightgray";
    }
  }

  //#endregion METHODS

  mounted() {

    // get wedding-rsvp-app firebase app
    this.firebaseApp = initializeWeddingRsvpFirebaseApp();
    if (this.firebaseApp == null) {
      return;
    }

    this.auth = getAuth(this.firebaseApp!);
  }


}
