import { IPhoto } from "@/models/IPhoto";

/** List of links to photos from the private google album with Regina and Dan's
 * chosen photos */
export const ourPhotoImageLinks: IPhoto[] = [
  {
    src: "https://res.cloudinary.com/dpejnozsc/image/upload/f_auto,q_auto/v1/ourphotos/xfmgddou8qxld13wwrec",
    description: "Regina-Dan tower by the Yale library.",
  },
  {
    src: "https://res.cloudinary.com/dpejnozsc/image/upload/f_auto,q_auto/v1/ourphotos/kmi8yl4xixnagkdmw1ge",
    description: "Classic orange-hood Dan",
  },
  {
    src: "https://res.cloudinary.com/dpejnozsc/image/upload/f_auto,q_auto/v1/ourphotos/vwmylen3wuyk9emfqoz2",
    description: "Dan and his mom during his highschool senior photos",
  },
  {
    src: "https://res.cloudinary.com/dpejnozsc/image/upload/f_auto,q_auto/v1/ourphotos/dqbojbrb3wiiu2uxwvc8",
    description: "Dan's sister Melissa in the Skylon Tower, Niagara Falls, Canada",
  },
  {
    src: "https://res.cloudinary.com/dpejnozsc/image/upload/f_auto,q_auto/v1/ourphotos/kkxu6uktmrnnknv6n4fu",
    description: "Dan graduating from Syracuse Univeristy, with his parents and sister Isabella",
  },
  {
    src: "https://res.cloudinary.com/dpejnozsc/image/upload/f_auto,q_auto/v1/ourphotos/flmnnrrmtksw5tkzm2pj",
    description: "Dan's award-winning climbing haloween costume",
  },
  {
    src: "https://res.cloudinary.com/dpejnozsc/image/upload/f_auto,q_auto/v1/ourphotos/wkvj32q1fvtmksaydf4v",
    description: "Pink Drink, with Tony and Nick",
  },
  {
    src: "https://res.cloudinary.com/dpejnozsc/image/upload/f_auto,q_auto/v1/ourphotos/ojdqdsydauguoaguqww2",
    description: "Red Rocks anchor with Kevin",
  },
  {
    src: "https://res.cloudinary.com/dpejnozsc/image/upload/f_auto,q_auto/v1/ourphotos/nryyi4elu8qtst9kxhcx",
    description: "Friends in Boston",
  },
  {
    src: "https://res.cloudinary.com/dpejnozsc/image/upload/f_auto,q_auto/v1/ourphotos/ca7elmjnvapwcgurehv0",
    description: "Snowboarding at Keystone(?) with Tony",
  },
  {
    src: "https://res.cloudinary.com/dpejnozsc/image/upload/f_auto,q_auto/v1/ourphotos/qrvntalrzjksnjnv2b3k",
    description: "lickin' good food with Rob",
  },
  {
    src: "https://res.cloudinary.com/dpejnozsc/image/upload/f_auto,q_auto/v1/ourphotos/ih2p7i20o98ghrdixro9",
    description: "Surprise Climbing Gym Birthday Party!",
  },
  {
    src: "https://res.cloudinary.com/dpejnozsc/image/upload/f_auto,q_auto/v1/ourphotos/zpbjzfpywckolzssunix",
    description: "When Dan finished his bike ride to LA",
  },
  {
    src: "https://res.cloudinary.com/dpejnozsc/image/upload/f_auto,q_auto/v1/ourphotos/guwoyoytrpd1pdtpupxx",
    description: "Devil's Tower, WY",
  },
  {
    src: "https://res.cloudinary.com/dpejnozsc/image/upload/f_auto,q_auto/v1/ourphotos/sitomx6yguombvceepux",
    description: "Victory beers with Tony atop a rock climb!",
  },
  {
    src: "https://res.cloudinary.com/dpejnozsc/image/upload/f_auto,q_auto/v1/ourphotos/IMG_1938-min_q7k4dk",
    description: "Vicky's wedding!",
  },
  {
    src: "https://res.cloudinary.com/dpejnozsc/image/upload/f_auto,q_auto/v1/ourphotos/00000IMG_00000_BURST20200119134730602_COVER-min_zpd3pg",
    description: "On Santa Monica Pier in LA",
  },
  {
    src: "https://res.cloudinary.com/dpejnozsc/image/upload/f_auto,q_auto/v1/ourphotos/IMG_20220521_192436006_HDR-min_afioqs",
    description: "Regina smiling about George's hiding spot during the proposal",
  },
  {
    src: "https://res.cloudinary.com/dpejnozsc/image/upload/f_auto,q_auto/v1/ourphotos/20220521_211211-min_bnrozj",
    description: "Friends about to hike out in the dark after the post-proposal picnic",
  },
  {
    src: "https://res.cloudinary.com/dpejnozsc/image/upload/f_auto,q_auto/v1/ourphotos/MVIMG_20190515_105018_1_-min_yixzkm",
    description: "In front of Bosco Vertical (Vertical Forrect) in Milan, Italy",
  },
  {
    src: "https://res.cloudinary.com/dpejnozsc/image/upload/f_auto,q_auto/v1/ourphotos/IMG_20180316_145641998-min_efwrva",
    description: "Dangling above the ocean while rock climbing in Gozo, Malta",
  },
  {
    src: "https://res.cloudinary.com/dpejnozsc/image/upload/f_auto,q_auto/v1/ourphotos/IMG954697-min_zyaafp",
    description: "Scott and Kathryne's wedding!",
  },
];