import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home" }
const _hoisted_2 = { class: "footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WeddingVideo = _resolveComponent("WeddingVideo")!
  const _component_WeddingPhotos = _resolveComponent("WeddingPhotos")!
  const _component_EngagementVideo = _resolveComponent("EngagementVideo")!
  const _component_ThankYou = _resolveComponent("ThankYou")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_WeddingVideo, { id: "weddingvideo" }),
    _createVNode(_component_WeddingPhotos, { id: "weddingphotos" }),
    _createVNode(_component_EngagementVideo, { id: "engagementvideo" }),
    _createVNode(_component_ThankYou, { id: "thankyou" }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", null, "Designed and created by Regina and Dan, " + _toDisplayString((new Date()).getFullYear()) + " <(*ΦωΦ*)>", 1)
    ])
  ]))
}