import IGuest from "./IGuest";

/** An interface for storing data about a particular party og wedding guests.
 * ome exampels of wedding guest parties: a family with kids, a engaged couple,
 * a friend who's bringing a plus one.  */
export default interface IGuestParty {

  /** A unique ID to identify this guest party */
  id: string;

  /** A lists of the IDs fo all guests in this party */
  guestIds: string[];

}

/**
 * Creates and returns a list of guest parties from the given list of guests.
 */
export function fromGuests(guests: IGuest[]): IGuestParty[] {

  const parties: IGuestParty[] = [];

  for (let guest of guests) {
    const matchingParties: IGuestParty[] = parties.filter(p => p.id === guest.invitationNumber);
    if(matchingParties.length == 1){
      matchingParties[0].guestIds.push(guest.id);
      continue;
    } else{
      const newParty: IGuestParty = {
        id: guest.invitationNumber,
        guestIds: [guest.id],
      }
      parties.push(newParty);
    }
  }

  return parties;
}