
import { Vue, Options } from 'vue-class-component';
import { yourPhotoImageLinks } from '@/assets/image-links/YourPhotoImageLinks';
import PhotoViewer from '@/components/PhotoViewer.vue';
import { IPhoto } from '@/models/IPhoto';

@Options({
  components: {
    PhotoViewer,
  },
})
export default class YourPhotos extends Vue {

  yourPhotos: IPhoto[] = yourPhotoImageLinks;

}
