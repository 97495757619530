<template>
  <section>

    <br />
    <h1>Regina + Daniel</h1>
    <br />
    <div class="video-container-container">
      <div class="video-container">
        <iframe
          title="Engagement Video"
          class="responsive-iframe"
          src="https://iplayerhd.com/player/video/f874abc6-29ed-490c-b382-015c0799f521"
          frameborder="0"
          scrolling="no"
          allowfullscreen
          mozallowfullscreen
          webkitallowfullscreen
          oallowfullscreen
          msallowfullscreen
        ></iframe>
      </div>
    </div>
    <p>Video Filmed and Produced by <a href="http://www.blacktievideo.com/">Black Tie Video</a></p>
    <p>See it in their official media player <a href="https://iplayerhd.com/player/regdan">here</a></p>
    <br />
    <h1> 9.9.2023 </h1>
    <h2> Boston, MA </h2>
    <br />

  </section>
</template>

<script lang="ts">


</script>

<style scoped>
.video-container-container {
  width: 100%;
  max-width: 600px;
  margin: 10px auto;
}

/* found here: https://www.w3schools.com/howto/howto_css_responsive_iframes.asp */
.video-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
  /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 16px;
}
</style>