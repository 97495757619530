<template>
  <div class="venue">

    <h1>Schedule</h1>
    <br />
    <br />
    <!-- Friday schedule -->
    <h2>Friday September 8th, 2023</h2>
    <div class="divider">
      <span></span>
    </div>
    <br />
    <br />

    <h3 class="padding-bottom-8">Welcome Reception</h3>
    <h4>Notch Brewery & Tap Room, 8:00pm - 10:00pm</h4>
    <a href="https://goo.gl/maps/ZMH23b2J6kSULFgs6">
      <h4 class="address padding-bottom-16">525 Western Ave, Brighton, MA 02135</h4>
    </a>
    <div id="img-container">
      <img
        src="@/assets/notch-brewery.jpg"
        alt="Photo of inside of Notch Brewery, with a bar, tables, and people drinking beer."
      />
    </div>

    <p>Come hang out with Dan, Regina, family, and friends for a chill evening
    of socializing, pretzles, and German beer before the big day.</p>
    <br />
    <p>Unfortunaley Notch <strong>doesn't allow anyone under 21</strong>, but they do permit
    outside food, as well as dogs in their year-round outdoor biergarten</p>

    <br />
    <br />
    <br />

    <!-- Saturday schedule -->
    <h2>Saturday September 9th, 2023</h2>
    <div class="divider">
      <span></span>
    </div>
    <br />

    <h3 class="padding-bottom-8">Climb Time</h3>
    <h4><a href="https://centralrockgym.com/watertown/">Central Rock Gym Watertown</a>, 9:00am - 12:00pm</h4>
    <a href="https://goo.gl/maps/Hxb5vZF3vfLremw68">
      <h4 class="address padding-bottom-16">74 Acton St, Watertown, MA 02472</h4>
    </a>
    <div id="img-container">
      <img
        src="@/assets/central-rock-gym-min.jpg"
        alt="Photo of inside of Central Rock Gym Watertown, a rock climbing gym, with a tall colerfull overhanging climbing wall, as well as some lower bouldering climbing walls."
      />
    </div>
    <p>See how Regina and Dan met, while enjoying some casual morning climbing. Planning on coming to this? Please let us know if you haven't already!</p>
    <br/>
    <br/>
    <h4 class="padding-bottom-16">Never climbed before, but want to try?</h4>
    <p class="padding-bottom-8">No problem! Just make sure to fill out <strong>all three waivers</strong> below beforehand.</p>
    <p>If you want to get a head start, you can also watch their <a
    style="text-decoration:underline"
    href="https://www.youtube.com/watch?v=goKtR4FmMsM">First Time Climber</a>
    and <a style="text-decoration:underline"
    href="https://www.youtube.com/watch?v=ImdCG43wjcs">Intro to Bouldering</a>
    videos before you arrive!</p>
    <br/>
    <br/>
    <h4 class="padding-bottom-16">Just want to hang out and watch, but not climb?</h4>
    <p>That's fine too, just make sure to fill out the <strong>Paticipation Waiver only</strong> from the waiver links below</p>
    <br/>
    <br/>
    <h4 class="padding-bottom-16">Have you climbed before, and want to lead?</h4>
    <p class="padding-bottom-8">If you haven't been to a CRG before (or if it's been a while), you'll
    still have to fill out all three waivers below.</p>
    <p class="padding-bottom-8">You can boulder after watching their <a
    style="text-decoration:underline"
    href="https://www.youtube.com/watch?v=ImdCG43wjcs">Bouldering Orientation
    video</a>, or climb on auto-belays after attending their in-person
    auto-belay orientation when you arrive (so don't be late!).</p>
    <p class="padding-bottom-8">If you want to climb ropes, you'll have to pass
    a TR or Lead test. The CRG TR/Lead certifications don't expire, so if
    you've passed one before you should be all set. If you aren't sure, reach out
    to CRG Watertown or Regina and Dan and they/we can check for you.</p>
    <p class="padding-bottom-8">Due to our group size and staffing, they may not
    be able to provide tests on Saturday morning. You can take the tests
    beforehand at this or any other CRG. We will also try to set up a time on
    Friday afternoon where you can come in for a lead tests if you'd like. </p>
    <p class="padding-bottom-8">If you have any questions or concerns about
    testing, please let us know and we will try to work something out!</p>
    <br/>
    <br/>
    <h4 id="waivers">Waivers</h4>
    <br/>
    <ul>
      <li class="padding-bottom-8"><a class="waiver-link" target="_blank" href="https://centralrockgym.com/watertown/gyms-waiver/">Paticipation Waiver</a></li>
      <li class="padding-bottom-8"><a class="waiver-link" target="_blank" href="https://centralrockgym.com/watertown/orientation-form/">Bouldering Waiver</a></li>
      <li class="padding-bottom-8"><a class="waiver-link" target="_blank" href="https://centralrockgym.com/auto-belay-orientation/">Auto-Belay Waiver</a></li>
    </ul>

    <br />
    <br />
    <br />

    <h3 class="padding-bottom-8">The Wedding</h3>
    <h4>Harry Parker Boathouse, 5:00pm - 11:00pm</h4>
    <a href="https://goo.gl/maps/H6ADyoVAtZqn1ccq6">
      <h4 class="address padding-bottom-16">20 Nonantum Rd, Brighton, MA 02135</h4>
    </a>

    <div id="img-container">
      <img
        src="@/assets/venue.jpg"
        alt="Panoramic image of the wedding video, with the outdoor event tent on the left, and the 2-story rectangular boathouse clad in fancy curvy light brown panels that intend mimic the waves of the Charles River on the right."
      />
    </div>

    <h4>Doors Open: 4:30pm</h4>
    <h4>Ceremony: 5:00pm</h4>
    <!-- <h4>Cocktail Hour: 6:00pm</h4>
    <h4>Dinner & Reception: 7:00pm - 11:00pm</h4> -->
    <br/>
    <p class="padding-bottom-8">The venue has ample free parking available.</p>
    <p class="padding-bottom-8">There will be a police detail onsite to ensure no one drives away intoxicated.</p>
    <p>The venue is a non-smoking establishment, including all outdoor areas.</p>

    <br />
    <br />
    <br />

    <!-- Sunday schedule -->
    <h2>Sunday, September 10th</h2>
    <div class="divider">
      <span></span>
    </div>
    <br />
    <br />

    <h3>Brunch</h3>
    <h4 class="padding-bottom-16">Regina's Backyard in Billerica, 11:30am - 3:30pm</h4>
    <p>A chill backyard gathering, and a great place to say congrats and/or goodbye. There may be live music and/or karaoke.</p>

    <br />
    <br />
    <br />

  </div>
</template>

<style scoped>
* {
  color: white;
}

h2 {
  font-size: xx-large;
}

h3 {
  font-family: 'Dancing Script', cursive;
  font-size: xx-large;
}

h4 {
  font-family: 'Montserrat', sans-serif;
}

p {
  font-family: 'Montserrat', sans-serif;
  max-width: 600px;
  margin: auto;
  font-style: italic;
}

a p {
  font-size: large;
}

a,
u {
  text-decoration: none;
}

img {
  border-radius: 16px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
  margin-bottom: 32px;
  width: 100%;
}

#img-container {
  width: 90vw;
  max-width: 800px;
  margin: auto;
}

.venue {
  padding: 32px 8px;
  background-color: #7C8048;
}

.address {
  font-style: italic;
  font-weight: normal;
  text-decoration: underline;
}

.divider {
  border-color: white;
  border-style: solid;
  border-width: 0 0 1px;
  height: 10px;
  line-height: 20px;
  text-align: center;
  overflow: visable;
  width: 50%;
  margin: auto;
}

.padding-bottom-32 {
  padding-bottom: 32px;
}

.padding-bottom-16 {
  padding-bottom: 16px;
}

.padding-bottom-8 {
  padding-bottom: 8px;
}

.waiver-link{
  font-family: 'Montserrat', sans-serif;
  font-style: italic;
  text-decoration: underline;
}
</style>