import { IPhoto } from "@/models/IPhoto";

/** List of links of professional photos from out wedding */
export const weddingPhotoImageLinks: IPhoto[] = [
  {
    src: "https://res.cloudinary.com/dpejnozsc/image/upload/f_auto,q_auto/v1/weddingphotos/aqpmbuwgzcbnxemqrdf2",
    description: "Just Married! (bubbles!)",
  },
  {
    src: "https://res.cloudinary.com/dpejnozsc/image/upload/f_auto,q_auto/v1/weddingphotos/hiok1qdnpuqemlgjogsm",
    description: "First look",
  },
    // {
  //   src: "https://res.cloudinary.com/dpejnozsc/image/upload/f_auto,q_auto/v1/weddingphotos/cea5ysy8fzhwclk7lj0v",
  //   description: "Don't touch the boats!",
  // },
  {
    src: "https://res.cloudinary.com/dpejnozsc/image/upload/f_auto,q_auto/v1/weddingphotos/kpyxlmskrwjqivsaihgo",
    description: "The Bridesmaids",
  },
  {
    src: "https://res.cloudinary.com/dpejnozsc/image/upload/f_auto,q_auto/v1/weddingphotos/rvusfuu8qioiati5bxfx",
    description: "Laughing Bridesmaids",
  },
  {
    src: "https://res.cloudinary.com/dpejnozsc/image/upload/f_auto,q_auto/v1/weddingphotos/ctb69n8fhxdp4pjd31vy",
    description: "The Groomsmen",
  },
  {
    src: "https://res.cloudinary.com/dpejnozsc/image/upload/f_auto,q_auto/v1/weddingphotos/ohuctrexda3j2u0rovcj",
    description: '"Grooms"',
  },
  {
    src: "https://res.cloudinary.com/dpejnozsc/image/upload/f_auto,q_auto/v1/weddingphotos/gjualjtbqi7mcsvcdswy",
    description: "Before the rain",
  },
  {
    src: "https://res.cloudinary.com/dpejnozsc/image/upload/f_auto,q_auto/v1/weddingphotos/peaibqtjvxf4wyir1klu",
    description: "After the rain",
  },
  {
    src: "https://res.cloudinary.com/dpejnozsc/image/upload/f_auto,q_auto/v1/weddingphotos/er20qsbkt086dcmdz4oy",
    description: '"Rain rain go away..."',
  },
  {
    src: "https://res.cloudinary.com/dpejnozsc/image/upload/f_auto,q_auto/v1/weddingphotos/y5lq00dnqx8jww8svqau",
    description: "Dr. Kevin Patel",
  },
  {
    src: "https://res.cloudinary.com/dpejnozsc/image/upload/f_auto,q_auto/v1/weddingphotos/kk9upgeatpxojxzidm0t",
    description: "A climber's wedding tradition on the 'Chuppa with a Hangboard'",
  },
  {
    src: "https://res.cloudinary.com/dpejnozsc/image/upload/f_auto,q_auto/v1/weddingphotos/lrcninwvdtctkptcdxfw",
    description: "Our first (married) kiss",
  },
  {
    src: "https://res.cloudinary.com/dpejnozsc/image/upload/f_auto,q_auto/v1/weddingphotos/dwwe3ax5fpfjmrfipbqz",
    description: "The Boba",
  },
  {
    src: "https://res.cloudinary.com/dpejnozsc/image/upload/f_auto,q_auto/v1/weddingphotos/nthywuxco0y5pu7p1xxi",
    description: "Our first dance",
  },
  {
    src: "https://res.cloudinary.com/dpejnozsc/image/upload/f_auto,q_auto/v1/weddingphotos/eoy6y1szrx3hzmiu2mbw",
    description: "The money dance and hora traditions",
  },
  {
    src: "https://res.cloudinary.com/dpejnozsc/image/upload/f_auto,q_auto/v1/weddingphotos/a7ylpe6vhazuc3xfjmra",
    description: "'The Marriage Begins' paparodydy",
  },
  {
    src: "https://res.cloudinary.com/dpejnozsc/image/upload/f_auto,q_auto/v1/weddingphotos/inrkicamyrhlt6evrkgw",
    description: "Spinning at Sunset",
  },
];