import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7a3c8a60"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "image-container" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = { class: "controls" }
const _hoisted_4 = ["disabled"]
const _hoisted_5 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", {
    class: "photos",
    onKeydown: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleKeyPress && _ctx.handleKeyPress(...args))),
    tabindex: "0",
    ref: "photoComponent"
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", {
        src: _ctx.currentImage.src,
        alt: _ctx.currentImage.description,
        onLoad: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onLoad && _ctx.onLoad(...args)))
      }, null, 40, _hoisted_2)
    ]),
    _createElementVNode("p", null, _toDisplayString(_ctx.currentImage.description), 1),
    _createElementVNode("p", null, _toDisplayString(_ctx.currentImageIndex + 1) + "/" + _toDisplayString(_ctx.images.length), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("button", {
        class: "button-on-purple",
        disabled: _ctx.loadingImage,
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.previousImage && _ctx.previousImage(...args)))
      }, "previous", 8, _hoisted_4),
      _createElementVNode("button", {
        class: "button-on-purple",
        disabled: _ctx.loadingImage,
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.nextImage && _ctx.nextImage(...args)))
      }, "next", 8, _hoisted_5)
    ])
  ], 544))
}