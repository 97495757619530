
import { Vue } from 'vue-class-component';

export default class CountDownTimer extends Vue {
  daysUntilWedding: string = "";
  intervalId: number = 0;

  /**
   * Calculates and returns the number of days left until dan and regina's wedding.
   */
  calcDaysUntilWedding(): string {
    let currentDate: Date = new Date();
    let weddingDate: Date = new Date("9/9/2023");
    let millisecondsPerDay: number = 86_400_000;
    let millisecondsUntilWedding: number = weddingDate.valueOf() - currentDate.valueOf(); // Typescript will not use the '-' operator on two date objects, so you have to use valueOf() to convert them to numbers (milliseconds), then then convert the timespace from milliseconds back to your desired unit later
    let daysUntilWedding: number = Math.ceil(millisecondsUntilWedding / millisecondsPerDay);
    return daysUntilWedding.toString();
  }

  mounted(): void{
    this.daysUntilWedding = this.calcDaysUntilWedding();
  }
  
  unmounted(): void {
  }

}

