
import { PropType } from 'vue';
import { Vue,Options} from 'vue-class-component';

@Options({
  components: {
  },
  props:{
    /** The full name of this bridal party member */
    name: {
      type: String,
      required: true,
    },
    /** The title of this bridal party member (maid of honor, groomsmen, etc) */
    titles: {
      type: Array as PropType<string[]>,
      requied: true,
    },
    /** A few sentences about this bridal party member */
    bio: {
      type: String,
      required: true,
    },
    /** An html path to the source of an image of this bridal party member */
    imgSrc: {
      type: String,
      required: true,
    },
    /** The alt (description) text matches the given image */
    imgAltText: {
      type: String,
      required: true,
    },
  }
})
export default class WeddingPartyMember extends Vue{
  name!: string
  titles!: string[]
  bio!: string
  imgSrc!: string
  imgAltText!: string
}
