
import { defineComponent } from 'vue';
import LodgingLink from '@/components/LodgingLink.vue';
import { IHotel } from '@/models/IHotel'

export default defineComponent({
  components: {
    LodgingLink,
  },
  data() {
    const fourPointsHotel: IHotel = {
      name: "Four Points by Sheraton",
      basePrice: 160,
      taxesAndFees: 19,
      parkingPolicy: "$15 / car / night",
      distanceToWeddingVenue: 1.4,
      phoneNumber: "877-822-6666",
      bookByDate: '8/11/23',
      description: "Constructed over the Mass Turn Pike and owned and operated by Marriott, this is a landmark everyone driving in and out of Boston is familiar with. An economical option located close to the wedding.",
    };
    const allstonStudioHotel: IHotel = {
      name: "Allston Studio Hotel",
      basePrice: 219,
      taxesAndFees: 73,
      parkingPolicy: "One free spot per room",
      distanceToWeddingVenue: 1.8,
      phoneNumber: "617-206-1848",
      bookByDate: '8/16/23 (Extended 1 week!)',
      description: "An boutique hotel with different art from local artists in each room. It's across the street from the Charles River, and adjacent to Regina and Dan's favorite Boston restaurant (Spring Shabu-Shabu).",
    };
    return {
      allstonStudioHotel,
      fourPointsHotel,
      /** The maximum height, in px, of the my google maps */
      maxMapHeight: 480,
      /** The maximum width, in px, of the my google maps */
      maxMapWidth: 640,
      mapWidth: 100,
      mapHeight: 100,
    };
  },
  mounted() {
    this.mapWidth = window.innerWidth < this.maxMapWidth ? window.innerWidth * 0.90 : this.maxMapWidth;
    this.mapHeight = this.mapWidth / 1.333333;
  },
})
