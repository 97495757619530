
import { Vue,Options} from 'vue-class-component';
import WeddingPartyMember from './WeddingPartyMember.vue';

@Options({
  components: { 
    WeddingPartyMember,
  },
})
export default class WeddingParty extends Vue{

}
