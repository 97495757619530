<template>
  <section>
    <h1>Thanks for coming!</h1>
    <br/>
    <p>We had the best day of our lives, mainly due to the presence of all our closest family and friends, so thanks everyone for making that happen!</p>
    <p>We'll make sure to upload and share some of our favorite photos and video clips once we have everything together!</p>
  </section>
</template>

<style scoped>

h1, p {
  color: white;
}
p {
  font-size: large;
}
section {
  background-color: #7C8048;
  padding: 32px 0;
}

</style>