
import { Vue, Options } from 'vue-class-component';

@Options({
    props: {
        registryLinkPath: String,
        imageDescription: String,
        imageFileName: String,
        registryGoalName: String,
    },
    computed: {
        baseImagePath: function (): string {
            return "/img/"
        }
    }
})
export default class RegistryLink extends Vue {
    registryLinkPath!: string
    imageFileName!: string
    registryGoalName!: string
    baseImagePath!: string
}
