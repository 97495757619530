<template>
  <div class="rsvp-link-container">
    <h1>RSVP</h1>
    <h3>With our big day drawing ever closer, please contact Regina or Dan directly if you need to update your RSVP.</h3>
    <!-- <router-link to="/rsvp">
      <button class="button-on-white">RSVP</button>
    </router-link> -->
  </div>
</template>

<style scoped>
h1 {
  margin-bottom: 16px;
}

h3 {
  color: gray;
  font-family: 'Montserrat', sans-serif;
  font-style: italic;
}

.rsvp-link-container {
  margin: 32px 0;
}

.bold {
  font-weight: bolder;
  color: #ff726f;
}
</style>
