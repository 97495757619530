
import { IPhoto } from '@/models/IPhoto';
import { PropType } from 'vue';
import { Vue, Options } from 'vue-class-component';

@Options({
  props: {
    images: Array as PropType<IPhoto[]>,
    backgroundColor: String,
  },
})
export default class YourPhotos extends Vue {

  //#region PROPS

  /** The background color of the component that contains this photo viewer */
  backgroundColor!: string;

  /** An array of all images from our CDN to display inside
   * this component */
  images!: IPhoto[];

  /** Trur if an image is currently loading */
  loadingImage: boolean = true;

  //#endregion PROPS

  //#region DATA

  /** The index of the image that is currently being displayed in the UI */
  currentImageIndex: number = 0;

  //#endregion DATA

  //#region COMPUTED

  /** The source string for the current image to display */
  get currentImage(): IPhoto {
    this.loadingImage = true;
    return this.images[this.currentImageIndex];
  }

  //#endregion COMPUTED

  //#region METHODS

  /** Shows the next image in the UI */
  nextImage(): void {
    if (this.currentImageIndex < this.images.length - 1) {
      this.currentImageIndex++;
    } else {
      this.currentImageIndex = 0;
    }
  }

  /** Shows the previous image in the UI */
  previousImage(): void {
    if (this.currentImageIndex > 0) {
      this.currentImageIndex--;
    } else {
      this.currentImageIndex = this.images.length - 1;
    }
  }

  onLoad(): void {
    this.loadingImage = false;
  }

  // Define a method to handle key presses
  handleKeyPress(event: KeyboardEvent): void {
    console.log('running key press function!')
    if (event.key === 'ArrowLeft') {
      this.previousImage();
    } else if (event.key === 'ArrowRight') {
      this.nextImage();
    }
  }

  //#endregion METHODS

  //#region LIFECYCLE HOOKS

  mounted() {
  }

  //#endregion LIFECYCLE HOOKS

}
