<template>
    <div class="not-found">
        <h1>You seem lost?</h1>
        <h3>Like Regina and Dan would be without eachother. [404]</h3>
        <br/>
        <br/>
        <router-link to="/">Return to Home Page</router-link>
    </div>
</template>

<style scoped>

.not-found{
    margin: 100px auto;
}

h1{
    margin-bottom: 40px;
}

</style>