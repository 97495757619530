import IGuest from "@/models/IGuest";
import IRsvpResponse, { createRsvpResponse } from "@/models/IRsvpResponse";
import { initializeApp, FirebaseApp } from "firebase/app";
import { getFirestore, Firestore, collection, DocumentData, CollectionReference, query, where, Query, getDoc, getDocs, QuerySnapshot, orderBy, limit, QueryDocumentSnapshot, doc, DocumentReference, DocumentSnapshot } from "firebase/firestore";

export const firebaseConfigWeddingRsvpApp = {
  apiKey: "AIzaSyBZhhZGdFpLjD4Rd6oYqXlhid45AH1Jpj4",
  authDomain: "wedding-rsvp-b920c.firebaseapp.com",
  projectId: "wedding-rsvp-b920c",
  storageBucket: "wedding-rsvp-b920c.appspot.com",
  messagingSenderId: "590533006713",
  appId: "1:590533006713:web:a50b19809bde30f3e70ba8",
  measurementId: "G-4TX0CSDEYF"
};

/** The name of the collection that contains a list of all wedding guests by ID
 * in Firestore */
export const guestCollectionName: string = 'guests';

/** The name of the collection that contains a list of all wedding guest parties
 * by invitation number */
export const partiesCollectionName: string = 'parties';

/** The name of the collection that contains a list of all rsvps sent so far */
export const rsvpsCollectionName: string = 'rsvps';

/**
 * Gets/initializes and returns a Firebase App based on the given firebase
 * config object.
 * @param firebaseConfig An bject containing all the necesary firebase config
 * info to get/initialize a Firebase App
 * @notes Logs status and errors to console.
 * @returns The initialized Firebase App, or null if the firebase app could not
 * be created.
 */
export function initializeFirebaseApp(firebaseConfig: object): FirebaseApp | null {
  console.log('Getting Firebase App...');
  try {
    const app: FirebaseApp = initializeApp(firebaseConfig);
    console.log('Got Firebase App');
    return app;
  } catch (error) {
    console.warn('Failed to initialize Firebase App');
    return null;
  }
}

/** Initializes and retusn the Firebase App for the Wedding RSVP app. */
export function initializeWeddingRsvpFirebaseApp(): FirebaseApp | null {
  return initializeFirebaseApp(firebaseConfigWeddingRsvpApp);
}

/** Gets / initializes a Firestore DB object.
 * @returns The Firestore DB object, or null if it could not be gotten.
 */
export function getFirestoreDatabase(): Firestore | null {
  console.log('Getting Firestore Database...');
  try {
    const firestoreDb: Firestore = getFirestore();
    console.log('Got Firestore database');
    return firestoreDb;
  } catch (error) {
    console.warn('Failed to get Firestore database');
    return null;
  }
}

/**
 * Get the ID of a guest from the given wedding database that matches the given
 * data (not case sensitive)
 * @param weddingDB The Firestore database that contains the wedding guest
 * collection
 * @param guestFirstName The first name of the Guest who's ID you want to get
 * (not case sensitive)
 * @param guestLastName The last name of the Guest who's ID you want to get (not
 * case sensitive)
 * @param guestEmail The email name of the Guest who's ID you want to get (not
 * case sensitive)
 * @returns The ID of the matching guest as a string. If no match was found,
 * null is returned.
 */
export async function getGuestId(weddingDB: Firestore, guestFirstName: string, guestLastName: string, guestEmail: string): Promise<string | null> {

  // get guest collection
  const guestsCollection: CollectionReference<DocumentData> = collection(weddingDB, guestCollectionName);

  // find a guest who's first, last, and email match the given values
  const guestQuery: Query<DocumentData> = query(
    guestsCollection,
    where("firstName", "==", guestFirstName.toLowerCase().trim()),
    where("lastName", "==", guestLastName.toLowerCase().trim()),
    where("email", "==", guestEmail.toLowerCase().trim())
  );

  // get matching guests(s) from database (should never be more than one)
  const matchingGuest: QuerySnapshot<DocumentData> = await getDocs(guestQuery);

  // if no matching guest was found, return null]
  if (matchingGuest.docs.length < 1) {
    return null;
  }

  // get and return ID of matching guest
  const matchingGuestId: string = matchingGuest.docs[0].get('id');
  return matchingGuestId;

}

/**
 * Fetches and returns a list of IDs of the guests who are in the given party.
 * @param weddingDB The Firestore wedding database to get the guest ids from
 * @param partyId The ID of the party to get the guests of.
 * @returns 
 */
export async function getGuestIds(weddingDB: Firestore, partyId: string): Promise<string[] | null> {

  // get parties collection
  const partiesCollection: CollectionReference<DocumentData> = collection(weddingDB, partiesCollectionName);

  // get the party document ref that matches the given party ID
  const partyDocRef: DocumentReference<DocumentData> = doc(partiesCollection, partyId);

  // get the actual document
  const partyDoc: DocumentSnapshot<DocumentData> = await getDoc(partyDocRef);

  // get and return the guest ids from the party document
  const guestIds: string[] = partyDoc.get('guestIds');
  return guestIds;

}

/**
 * Returns a list of guests, each corresponding to one of the given Guest IDs.
 * @param weddingDB The database to get the guest data from
 * @param guestIds the ids of the guests to get the data for
 * @returns 
 */
export async function getGuests(weddingDB: Firestore, guestIds: string[]) {

  // get guest collection
  const guestsCollection: CollectionReference<DocumentData> = collection(weddingDB, guestCollectionName);

  const guests: IGuest[] = []
  for (let guestId of guestIds) {
    // get the guest document ref that matches the guest ID
    const guestDocRef: DocumentReference<DocumentData> = doc(guestsCollection, guestId);

    // get the actual guest document
    const guestDoc: DocumentSnapshot<DocumentData> = await getDoc(guestDocRef);

    // convert doc to object
    const guest: IGuest = {
      id: guestId,
      firstName: guestDoc.get('firstName'),
      lastName: guestDoc.get('lastName'),
      email: guestDoc.get('email'),
      hasPlusOne: guestDoc.get('hasPlusOne'),
      invitationNumber: guestDoc.get('invitationNumber'),
    }

    guests.push(guest);
  }

  return guests;
}

/**
 * Gets the ID of the party from the given wedding database that contains the
 * given guest ID.
 * @param weddingBD The Firestore database that contains the wedding guest
 * collection
 * @param guestId The ID of a guest who's party you are looking for.
 * @returns 
 */
export async function getPartyId(weddingBD: Firestore, guestId: string): Promise<string | null> {

  // get parties collection
  const partiesCollection: CollectionReference<DocumentData> = collection(weddingBD, partiesCollectionName);

  // find the part that contains the given guest id
  const partiesQuery: Query<DocumentData> = query(
    partiesCollection,
    where("guestIds", "array-contains", guestId)
  );

  // get matching party from database (should never be more than one)
  const matchingParties: QuerySnapshot<DocumentData> = await getDocs(partiesQuery);

  // if no matching party was found, return null]
  if (matchingParties.docs.length < 1) {
    return null;
  }

  // get and return ID of matching party
  const matchingPartyId: string = matchingParties.docs[0].id;
  return matchingPartyId;

}

/**
 * Get the latest RSVP sent for this party from the Database, if there is one.
 * @param weddingDB The wedding database to get the RSVP from
 * @param partyId The ID of the party that to get the RSVP for
 * @returns The latest RSVP response the party with the given ID sent, or null
 * of that party has not sent an RSVP yet.
 */
export async function getLatestRsvp(weddingDB: Firestore, partyId: string): Promise<IRsvpResponse | null> {

  // get rsvps collection
  const rsvpsCollection: CollectionReference<DocumentData> = collection(weddingDB, rsvpsCollectionName);

  // get the latest RSVPs sent by this party
  const rsvpsQuery: Query<DocumentData> = query(
    rsvpsCollection,
    where("partyId", "==", partyId),
    orderBy("dateCreated", "desc"),
    limit(1)
  );

  // get matching rsvps from database (should never be more than one)
  const matchingRsvps: QuerySnapshot<DocumentData> = await getDocs(rsvpsQuery);

  // if no previous rsvp was found, return null
  if (matchingRsvps.docs.length < 1) {
    return null;
  }

  // create and return a response object out of the found RSVP
  const matchingRsvpDoc: QueryDocumentSnapshot<DocumentData> = matchingRsvps.docs[0];
  const matchingRsvp: IRsvpResponse = createRsvpResponse(
    matchingRsvpDoc.get('responderId'),
    matchingRsvpDoc.get('partyId'),
    matchingRsvpDoc.get('guestsWhoSaidYesIds'),
    matchingRsvpDoc.get('guestsWhoSaidNoIds'),
    matchingRsvpDoc.get('plusOne'),
    matchingRsvpDoc.get('adviceOrJokes'),
    matchingRsvpDoc.get('songRecommendations'),
    matchingRsvpDoc.get('reasonForDecline'),
    matchingRsvpDoc.get('dateCreated'),
  )

  return matchingRsvp;

}

//todo: comment
export async function getAllParties(weddingDB: Firestore): Promise<QuerySnapshot<DocumentData> | null> {

  // get parties collection
  const partiesCollection: CollectionReference<DocumentData> = collection(weddingDB, partiesCollectionName);

  const allPartyDocs: QuerySnapshot<DocumentData> = await getDocs(query(partiesCollection));
  
  return allPartyDocs;
}
