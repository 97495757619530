
import { Vue,Options} from 'vue-class-component';
import { ourPhotoImageLinks } from '@/assets/image-links/OurPhotoImageLinks';
import PhotoViewer from '@/components/PhotoViewer.vue';
import { IPhoto } from '@/models/IPhoto';

@Options({
  components: {
    PhotoViewer,
  },
})
export default class YourPhotos extends Vue{

  ourImages: IPhoto[] = ourPhotoImageLinks;

}
