
import { Vue,Options} from 'vue-class-component';

@Options({
  components: { 
  },
})
export default class Map extends Vue{
  mapWidth!: number;
  maxMapWidth!: number;
  mapHeight!: number;
  data() {
    return {
      /** The maximum height, in px, of the my google maps */
      maxMapHeight: 480,
      /** The maximum width, in px, of the my google maps */
      maxMapWidth: 640,
      mapWidth: 100,
      mapHeight: 100,
    }
  }
  mounted() {
    this.mapWidth = window.innerWidth < this.maxMapWidth ? window.innerWidth * 0.90 : this.maxMapWidth;
    this.mapHeight = this.mapWidth / 1.333333;
  }
}
