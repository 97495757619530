import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import NotFound from '../views/NotFound.vue'
import RsvpView from '../views/RsvpView.vue'
import BackEnd from '../views/BackEnd.vue'
import PhotoUpload from '../views/PhotoUpload.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  // // RSVP form page
  // {
  //   path: '/rsvp',
  //   name: 'Rsvp',
  //   component: RsvpView,
  // },
  // page for guests to upload photos
  // {
  //   path: '/photoupload',
  //   name: 'PhotoUpload',
  //   component: PhotoUpload,
  // },
  // backend upload page
  {
    path: '/backend',
    name: 'BackEnd',
    component: BackEnd,
  },
  // catch all 404
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  // below method alows for the use of anchor links in vue router
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        top: 59, // scrolls to this many pixels ABOVE the element that we are scrolling to (see more here: https://router.vuejs.org/guide/advanced/scroll-behavior.html)
      };
    }
    // scrolls to top of window if now anchor tag was provided in link. The standard vue solution [ return { x: 0, y: 0 } ] was throwing a compile-time error for me, but this solution, found at the github link below, solved it for me!
    // https://github.com/vuejs/vue-router/issues/3451
    window.scrollTo(0, 0);
  }
})

export default router
