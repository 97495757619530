
import NavBar from './components/NavBar.vue';

export default {
    mounted() {
        // This is way number % to modify the website title, from the below link. Seemed the easiest to impliment for me, and I can change it at different routes / components if I want:
        // https://stackoverflow.com/questions/62023604/where-to-find-or-how-to-set-htmlwebpackplugin-options-title-in-project-created-w
        document.title = "Regina and Dan Wedding";
    },
    components: { NavBar }
}
