<template>
  <section>

    <br />
    <h1>Engagement Video</h1>
    <br />
    <div class="video-container-container">
      <div class="video-container">
        <iframe
          class="responsive-iframe"
          src="https://www.youtube-nocookie.com/embed/pAdByKhdmMA"
          title="Engagement Video"
          frameborder="0"
          allowfullscreen
        >
        </iframe>
      </div>
    </div>
    <br />
    <br />

  </section>
</template>

<script lang="ts">


</script>

<style scoped>
.video-container-container {
  width: 100%;
  max-width: 600px;
  margin: 10px auto;
}

/* found here: https://www.w3schools.com/howto/howto_css_responsive_iframes.asp */
.video-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
  /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 16px;
}
</style>