import { IPhoto } from "@/models/IPhoto";

/** List of links to photos from the private google album with Regina and Dan's
 * chosen photos */
export const yourPhotoImageLinks: IPhoto[] = [
  {
    src: "https://res.cloudinary.com/dpejnozsc/image/upload/f_auto,q_auto/v1/yourphotos/tlc6zhs13wgi9hct5sen",
    description: "Baby Dan, from Dan's dad",
  },
  {
    src: "https://res.cloudinary.com/dpejnozsc/image/upload/f_auto,q_auto/v1/ourphotos/c8n88neekfvfxrxv6zjv", //Note: from the 'our photos' folder cause I uploaded it there first
    description: "When your drink matches your skin tone, with Nick and Papa Steve",
  },
  {
    src: "https://res.cloudinary.com/dpejnozsc/image/upload/f_auto,q_auto/v1/yourphotos/lqp8jae0ip0cfu0t1cbn",
    description: "Halloween 2011(?), from Papa Steve",
  },
  {
    src: "https://res.cloudinary.com/dpejnozsc/image/upload/f_auto,q_auto/v1/yourphotos/x9zh9frj6xvjy6htheyo",
    description: "Beaux Arts Ball, I think, with Papa Steve and Alex",
  },
  {
    src: "https://res.cloudinary.com/dpejnozsc/image/upload/v1692824691/yourphotos/eyfjs7j2zfh7rivexuna.jpg",
    description: "Papa Steve and Papa Steve graduating from Syracuse University, 2014",
  },
  {
    src: "https://res.cloudinary.com/dpejnozsc/image/upload/f_auto,q_auto/v1/yourphotos/hnkrpmclhfetycsn5rqz",
    description: "Angelica, Angelica, and Angelica, from a Spain",
  },
  {
    src: "https://res.cloudinary.com/dpejnozsc/image/upload/f_auto,q_auto/v1/yourphotos/og73szznzclj8sspehlw",
    description: "Daniel with his sister Melissa",
  },
  {
    src: "https://res.cloudinary.com/dpejnozsc/image/upload/f_auto,q_auto/v1/yourphotos/atxinrmbykueasjtoytm",
    description: "Dan with his Melissa, Andrea, and Dan's Mom, from Melissa.",
  },
  {
    src: "https://res.cloudinary.com/dpejnozsc/image/upload/f_auto,q_auto/v1/yourphotos/ibhxhtnqo8lgvsconjzk",
    description: "Baby Dan, from Dan's mom",
  },
  {
    src: "https://res.cloudinary.com/dpejnozsc/image/upload/f_auto,q_auto/v1/yourphotos/f7flrr5yg4bmmpoi6oze",
    description: "Dan leaving for some boyscout trip, from Dan's mom",
  },
  {
    src: "https://res.cloudinary.com/dpejnozsc/image/upload/f_auto,q_auto/v1/yourphotos/gsznjcdp8s4tjzbsxkvr",
    description: "Dan with his mom, 2 sisters, cousin, autn, and unkle in Niagara Falls, from Dan's mom",
  },
  {
    src: "https://res.cloudinary.com/dpejnozsc/image/upload/f_auto,q_auto/v1/yourphotos/qaofx01xk90o3xkutfki",
    description: "Dan's sister Melissa holding him, from Dan's mom",
  },
  {
    src: "https://res.cloudinary.com/dpejnozsc/image/upload/f_auto,q_auto/v1/yourphotos/ysgccyqvce8czi21fxdl",
    description: "Dan's Birthday (?), from Dan's mom",
  },
  {
    src: "https://res.cloudinary.com/dpejnozsc/image/upload/f_auto,q_auto/v1/yourphotos/hbfayamfly8wdcmevqkv",
    description: "Batdan with mom and 2 sisters, from Dan's mom",
  },
  {
    src: "https://res.cloudinary.com/dpejnozsc/image/upload/f_auto,q_auto/v1/yourphotos/vaprbuxir3sxtczmqb5m",
    description: "Dan the Tank Engine and Barnelissa, from Dan's mom",
  },
  {
    src: "https://res.cloudinary.com/dpejnozsc/image/upload/f_auto,q_auto/v1/yourphotos/tlaedmrmjvuro6odxind",
    description: "Dan (center) on his highschool drumline, from Dan's mom",
  },
  {
    src: "https://res.cloudinary.com/dpejnozsc/image/upload/f_auto,q_auto/v1/yourphotos/aig3mtf8yhlbczgq5juf",
    description: "Shelf Road Shenanigans, from Tin & Quinny",
  },
  {
    src: "https://res.cloudinary.com/dpejnozsc/image/upload/f_auto,q_auto/v1/yourphotos/vuweqphjpnwvmz43siws",
    description: "Anchor Time, with Stacy & Tony (top of Roadside Attraction in RRG, I think?)",
  },
  {
    src: "https://res.cloudinary.com/dpejnozsc/image/upload/f_auto,q_auto/v1/yourphotos/alhxphbbqbexutixgwsn",
    description: "Hammock Time, with Stacy",
  },
  {
    src: "https://res.cloudinary.com/dpejnozsc/image/upload/f_auto,q_auto/v1/yourphotos/ynxwa1olmvktxso4vfcb",
    description: "Climbing in Eldorado Canyon (I think?), with Laralyn",
  },
  {
    src: "https://res.cloudinary.com/dpejnozsc/image/upload/f_auto,q_auto/v1/yourphotos/b7yl7jiftdvl1chtnjpw",
    description: "Regina atop the crack, from Laralyn",
  },
  {
    src: "https://res.cloudinary.com/dpejnozsc/image/upload/f_auto,q_auto/v1/yourphotos/xjsrq8xhullymorbqpft",
    description: "bathang buddies, from Laralyn",
  },
  {
    src: "https://res.cloudinary.com/dpejnozsc/image/upload/f_auto,q_auto/v1/yourphotos/oyf58gfjfbhupdo6nfy7",
    description: "Friends having a laugh, from Laralyn",
  },
  {
    src: "https://res.cloudinary.com/dpejnozsc/image/upload/f_auto,q_auto/v1/yourphotos/kwjznh99vzubgrsbvoxp",
    description: "Playground Regina, from Regina's Mom",
  },
  {
    src: "https://res.cloudinary.com/dpejnozsc/image/upload/f_auto,q_auto/v1/yourphotos/x9xalkva7bnzrwlvau5v",
    description: "Towel-head Regina, from Regina's Mom",
  },
  {
    src: "https://res.cloudinary.com/dpejnozsc/image/upload/f_auto,q_auto/v1/yourphotos/epfpokckuo6rkqu61wdc",
    description: "Small(er) Regina, from Regina's Mom",
  },
  {
    src: "https://res.cloudinary.com/dpejnozsc/image/upload/f_auto,q_auto/v1/yourphotos/unnwc5foujnzvuibye0d",
    description: "Farley on a Regina on a Dan, from Tony Ly(?)",
  },
  {
    src: "https://res.cloudinary.com/dpejnozsc/image/upload/f_auto,q_auto/v1/yourphotos/amt8wzc3c0smxul91ocg",
    description: "Regina, Dan, George, and Emerson climbing in Eldorado Canyon, CO, from George Boe",
  },
  {
    src: "https://res.cloudinary.com/dpejnozsc/image/upload/f_auto,q_auto/v1/yourphotos/vyx4fjp3nshjbdpxbsn8",
    description: "Dan and Regina sitting together in the climbing gym, from Tony Ly",
  },
];